<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 66 67"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_542_26634)">
      <rect x="3" y="2" width="60" height="60" rx="12" fill="white" />
      <rect
        x="3.75"
        y="2.75"
        width="58.5"
        height="58.5"
        rx="11.25"
        stroke="#EAECF0"
        stroke-width="1.5"
      />
      <path
        d="M26.75 30.75V25.75C26.75 24.0924 27.4085 22.5027 28.5806 21.3306C29.7527 20.1585 31.3424 19.5 33 19.5C34.6576 19.5 36.2473 20.1585 37.4194 21.3306C38.5915 22.5027 39.25 24.0924 39.25 25.75V30.75M24.25 30.75H41.75C43.1307 30.75 44.25 31.8693 44.25 33.25V42C44.25 43.3807 43.1307 44.5 41.75 44.5H24.25C22.8693 44.5 21.75 43.3807 21.75 42V33.25C21.75 31.8693 22.8693 30.75 24.25 30.75Z"
        stroke="#344054"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_542_26634"
        x="0"
        y="0.5"
        width="66"
        height="66"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1.5" />
        <feGaussianBlur stdDeviation="1.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_542_26634" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_542_26634"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '67',
    },
    width: {
      type: String,
      default: '66',
    },
    color: {
      type: String,
      default: 'label-text',
    },
  },
  computed: {
    /**
     * Icon Style
     * @param {void}
     * @returns {string} - Color
     * @description Assigns the specific color to the icon
     */
    iconStyle: function () {
      return {
        fill: ['$' + this.color, `var(--${this.color})`],
      }
    },
  },
}
</script>
